import * as UI from "@goodgym/components";
import { Filters } from "@goodgym/components/SessionsFeed/components";
import { State } from "@goodgym/components/SessionsFeed/types";
import React from "react";

export type FilterPanelProps = UI.BoxProps & {
  state: State;
};
export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

export const FilterPanel: React.FC<
  React.PropsWithChildren<FilterPanelProps>
> = ({ state, ...props }) => {
 

  return (
    <UI.Card
      sx={{ display: { xs: "none", md: "block" }, mt: 1, mb: 3, width: "100%" }}
    >
      <UI.CardContent>
        <UI.Typography variant="subtitle1" color="neutral.dark" sx={{}}>
          <UI.Box
            component="span"
            sx={{
              alignSelf: "center",
              display: "flex",
              flexDirection: "column",
              my: 1,
            }}
          >
            Filters
          </UI.Box>
        </UI.Typography>

        <Filters state={state} />
      </UI.CardContent>
    </UI.Card>
  );
};
