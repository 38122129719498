/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import { State } from "@goodgym/components/SessionsFeed/types";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import React from "react";
import type { DrawerView } from "../..";

export type HeaderProps = UI.BoxProps & {
  state: Pick<State, "items" | "more" | "drawers" | "view" | "setView">;
  setDrawerView: (view: DrawerView) => void;
};



const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  state,
  setDrawerView,
  ...props
}) => {
  const { items, more, drawers } = state;

  return (
    <>
      {/* Tablet->desktop header */}
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          backgroundColor: "primary.main",
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          py: 2,
          zIndex: 3,
        }}
      >
        <UI.Container>
          <UI.Breadcrumbs
            crumbs={[
              ["Home", u.links.home()],
              ["Sessions", u.links.sessions()],
            ]}
            sx={{ mb: 1 }}
          />
          <UI.Typography variant="h1">Find sessions near you</UI.Typography>
        </UI.Container>
      </UI.Box>

      {/*
       * To get a fixed position header for just the width of the
       * feed column and not the filters panel on the right,
       * we create a grid with a single column of width 7.
       * Because the grid container stretches the full width of the page
       * we need to set pointerEvents to none so it doesn't prevent us
       * clicking on the filter panel. Ugly.
       */}
      <UI.Box
        sx={{
          display: { xs: "none", md: "block" },
          pointerEvents: "none",
          position: "fixed",
          top: "185px",
          left: 0,
          right: 0,
          zIndex: 2,
        }}
      >
        <UI.Container>
          <UI.Grid container spacing={2} sx={{ pt: 2 }}>
            <UI.Grid
              item
              md={8}
              sx={{
                backgroundColor: "#f5f5f5",
                pointerEvents: "auto",
                pb: 2,
              }}
            >
              <UI.Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <UI.Box sx={{ flexGrow: 1 }}>
                  <UI.SessionsFeed.ResultCount
                    more={more}
                    count={items.length}
                  />
                </UI.Box>
              </UI.Box>
            </UI.Grid>
          </UI.Grid>
        </UI.Container>
      </UI.Box>

      {/* Mobile header */}
      <UI.Container
        sx={{
          display: { xs: "block", md: "none" },
          pt: 2,
          pb: 2,
          position: "fixed",
          top: { xs: "56px", sm: "64px" },
          background: "white",
          zIndex: 2,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <UI.Typography variant="h3" sx={{ whiteSpace: "nowrap" }}>
          Sessions near you
        </UI.Typography>

        <UI.Grid container spacing={1} sx={{ pt: 2 }}>
          <UI.Grid item xs={12}>
            <UI.Button
              fullWidth
              variant="outlined"
              color="neutral"
              onClick={(event) => {
                drawers.filters.onClick(event);
                setDrawerView("FILTER_VIEW");
              }}
            >
              <Icons.FilterList sx={{ mr: "4px" }} /> Filters
            </UI.Button>
          </UI.Grid>
        </UI.Grid>
      </UI.Container>
    </>
  );
};

export default Header;
