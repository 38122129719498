import * as UI from "@goodgym/components";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import NearMeIcon from "@mui/icons-material/NearMe";
import React, { useState } from "react";
import * as C from "..";
import { State } from "../../types";
import { EnabledSections } from "../../types"

export type FiltersProps = UI.BoxProps & {
  state: Pick<
    State,
    | "areas"
    | "availableTypes"
    | "drawers"
    | "sections"
    | "items"
    | "filters"
    | "mapPeriod"
    | "more"
    | "runner"
  >;
  areaPage?: boolean;
};

const Filters: React.FC<FiltersProps> = ({ state, areaPage = false, ...props }) => {
  const { filters, sections, drawers } = state;
  const theme = UI.useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));
  const [currentTab, setCurrentTab] = useState(areaPage ? "postcode" : "areas");
  
  const handleSectionChange = (
    e: React.MouseEvent<HTMLElement>,
    section: keyof EnabledSections
  ) => {
    if(section) {
      setCurrentTab(section);
      sections.toggle(section);
    }
  };

  return (
    <UI.Box {...props}>
      <C.SessionTypesFilter state={state} sx={{ mb: 4 }} />
      <UI.Box sx={{ display: "flex" }}>
        {!areaPage && 
          <UI.ToggleButtonGroup
            value={currentTab}
            color="secondary"
            onChange={handleSectionChange}
            exclusive
            fullWidth
            sx={{ py: 1, flexGrow: 1 }}
          >
            <UI.ToggleButton
              value={"areas"}
              onClick={() => {
                if(currentTab !== "areas") {
                  filters.update({ areaIds: state.filters.values.areaIds });
                }
              }}
            >
              <LocationCityIcon sx={{ pr: 0.5, fontSize: "22px" }} />
              Areas
            </UI.ToggleButton>
            <UI.ToggleButton 
              value={"postcode"}
              
            >
              <NearMeIcon sx={{ pr: 0.5, fontSize: "22px" }} />
              Postcode
            </UI.ToggleButton>
          </UI.ToggleButtonGroup>
        }
      </UI.Box>
      {currentTab === "areas" && (
        <C.AreasFilter
          state={state}
          sx={{ mb: 3 }}
        />
      )}

      {currentTab === "postcode" && (
        <>
          <C.PostcodeFilter state={state} sx={{ mb: 4 }} />
          <C.DistanceFilter state={state} sx={{ mb: 4 }} />
        </>
        
      )}

      {md ? (
        <UI.Box></UI.Box>
      ) : (
        <UI.Grid container spacing={2}>
          <UI.Grid item xs={12}>
            <UI.Button
              color="secondary"
              fullWidth
              onClick={drawers.filters.onClose}
            >
              Apply
            </UI.Button>
          </UI.Grid>
        </UI.Grid>
      )}
    </UI.Box>
  );
};

export default Filters;
