/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type ListingCarouselProps = UI.BoxProps & {
  listings: (T.SessionCardFragment | T.TaskRequestCardFragment)[];
  onClose?: () => any;
};

const ListingCarousel: React.FC<ListingCarouselProps> = ({
  listings,
  onClose,
  ...props
}) => {
  const [index, setIndex] = React.useState(0);
  const listing = listings[index];

  const next = () => {
    setIndex(Math.min(index + 1, listings.length - 1));
  };

  const previous = () => {
    setIndex(Math.max(index - 1, 0));
  };

  return (
    <UI.Box {...props}>
      <UI.ListingCard key={listing.__typename + listing.id} listing={listing} />
      <UI.Paper
        elevation={1}
        sx={{
          display: "flex",
          mt: 1,
          p: 1,
          backgroundColor: "white.main",
          alignItems: "center",
        }}
      >
        <UI.IconButton disabled={index === 0} onClick={previous}>
          <Icons.ArrowBack />
        </UI.IconButton>
        <UI.IconButton
          sx={{ ml: 1 }}
          disabled={index >= listings.length - 1}
          onClick={next}
        >
          <Icons.ArrowForward />
        </UI.IconButton>

        <UI.Typography
          variant="subtitle2"
          sx={{
            color: "neutral.main",
            verticalAlign: "center",
            ml: 2,
          }}
        >
          {index + 1} of {listings.length}
        </UI.Typography>
        <UI.Box flexGrow={1} />
        {onClose && (
          <UI.IconButton sx={{ ml: 2 }} onClick={onClose}>
            <Icons.Close />
          </UI.IconButton>
        )}
      </UI.Paper>
    </UI.Box>
  );
};

export default ListingCarousel;
