import * as UI from "@goodgym/components";
import IconCards from "@goodgym/components/IconCards";
import { useGetRunnersQuery } from "@goodgym/graphql/hooks";
import { whyHelp } from "@goodgym/pages/AboutPage/AboutCommunityMissionPage/data";
import Testimonial from "@goodgym/pages/AboutPage/components/Testimonials";
import * as data from "../../data";
import * as Images from "../../images";

const AboutGroupRun = () => {
  const { data: info } = useGetRunnersQuery({
    variables: {
      random: true,
      limit: 3,
    },
  });

  const areaActivators = info?.runners.map((runner) => ({
    avatarUrl: runner.avatarUrl,
    quote: runner.bio,
    by: runner.name,
    area: runner.homeArea?.name
  }));

  return (
    <UI.Box>
      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 1,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          We work out by helping local community projects.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.taskIconData.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h2"
          sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
        >
          Find your local group
        </UI.Typography>
      </UI.Container>
      <UI.Container sx={{ textAlign: "center" }}>
        <UI.Button
          href="/v3/sessions?type=GroupRun"
          sx={{ mb: 3 }}
        >
          Find Group Runs
        </UI.Button>
      </UI.Container>
      <UI.Box
        sx={{
          backgroundImage: `url(${Images.GroupRunBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "380px",
          width: "100vw",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          aspectRatio: "5/3",
        }}
      >
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textShadow: 7, m: 6 }}
        >
          How it works
        </UI.Typography>
      </UI.Box>

      <UI.Container>
        <UI.Typography
          variant="h5"
          sx={{
            lineHeight: 1.8,
            fontWeight: "normal",
            marginTop: 3,
            marginBottom: 3,
            textAlign: "center",
          }}
        >
          Turn up for our weekly group session once a week. New members are
          always welcome and we never leave anyone behind.
        </UI.Typography>
        <UI.Container>
          <UI.Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: "30px",
              // height: "300px",
              mb: 4,
            }}
          >
            {data.groupRunTasks.map((element, index) => (
              <UI.Grid
                item
                xs={12}
                md={5}
                lg={3}
                key={index}
                sx={{
                  textAlign: "center",
                  mb: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UI.Box sx={{ height: "120px" }}>
                  <img
                    style={{ width: "130px", height: "auto" }}
                    src={element.image}
                    alt="icon"
                  />
                </UI.Box>

                <UI.Box
                  sx={{
                    width: { xs: "60%", sm: "50%", md: "100%" },

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTop: "4px solid #E11018",
                  }}
                />
                <UI.Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mx: { xs: 2, md: 0 },
                  }}
                >
                  <element.icon sx={{ mx: 1 }} fontSize="medium" />
                  <UI.Typography
                    sx={{
                      textAlign: "center",
                      p: 1,
                    }}
                    variant="h3"
                  >
                    {element.title}
                  </UI.Typography>
                </UI.Box>

                <UI.Typography
                  sx={{
                    textAlign: "center",
                    p: 1,
                  }}
                  variant="body1"
                >
                  {element.tagline}
                </UI.Typography>
              </UI.Grid>
            ))}
          </UI.Grid>
        </UI.Container>
        <UI.Box>
          <Testimonial testimonials={areaActivators} />
        </UI.Box>

        <UI.Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {whyHelp.map((element, index) => (
            <IconCards
              key={index}
              image={element.image}
              tagline={element.tagline}
              bold={true}
            />
          ))}
        </UI.Grid>
      </UI.Container>
    </UI.Box>
  );
};

export default AboutGroupRun;
