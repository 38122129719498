import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import * as T from "@goodgym/graphql/types";
import { FaqData, RunnersTestimonials } from "../AboutCommunityMissionPage/data";
import FAQ from "../components/FAQ";
import GetInvolvedCard from "../components/GetInvolvedCard";
import MidBanner from "../components/MidBanner";
import NewsQuotes from "../components/NewsQuotes";
import TestimonialRunners from "../components/TestimonialsRunners";
import { MissionBanner } from "../images";
import AboutGroupRun from "./components/AboutGroupRun";
import * as Images from "./images";

const AboutGroupRunsPage = () => {

  const { data } = T.useHomePageQuery();

  const numberOfTasks = data?.stats.tasks;

  return (
    <UI.Box sx={{}}>
      <Banner
        title="Run, walk or cycle to help community projects with practical tasks"
        image={Images.GroupRunHeader}
        buttonText={"Sign up"}
        linkForButton="https://www.goodgym.org/users/sign_up"
      />
      <AboutGroupRun />
      <MidBanner
        numberOfDeeds={numberOfTasks}
        badge={Images.BadgeMission}
        subtitle="Good deeds completed"
      />
      <UI.Box
        sx={{
          backgroundImage: `url(${MissionBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "600px",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "5/3",
        }}
      />
      <GetInvolvedCard groupRunPage={true} />
      =
      <TestimonialRunners testimonials={RunnersTestimonials} />
      <UI.Container sx={{ mt: 8, mb: 6 }}>
        <UI.Typography variant="h4" sx={{ my: 2 }}>
          What people say about our community
        </UI.Typography>
        <NewsQuotes />
      </UI.Container>
      <UI.Container sx={{ my: 3 }}>
        <FAQ info={FaqData} />
      </UI.Container>
      <UI.Box
        sx={{
          height: "200px",
          p: 4,
          mt: 5,
          mb: { xs: 9, md: 0 },
        }}
      >
        <UI.Container sx={{ textAlign: "center" }}>
          <UI.Typography
            variant="h2"
            sx={{ my: 2, textAlign: "center", fontStyle: "normal" }}
          >
            Find your local group
          </UI.Typography>
          <UI.Container sx={{ textAlign: "center" }}>
            <UI.Button href="/v3/sessions?type=GroupRun" sx={{ mb: 3 }}>
              Find Group Runs
            </UI.Button>
          </UI.Container>
        </UI.Container>
      </UI.Box>
    </UI.Box>
  );
};

export default AboutGroupRunsPage;
