/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { State } from "../../types";
import * as h from "./helpers";
import ReactCalendar, { CalendarTileProperties } from "react-calendar";
import { useStyles } from "./styles";

export type CalendarProps = UI.CardProps & {
  state: Pick<State, "filters" | "sections">;
};

/*
 * In this component we use some of the parts of the state
 * (the filters, mostly) but we refetch our own data, as we
 * need it for a different time-frame (the whole currently selected month)
 * whereas the feed only needs it from the currently selected date on
 */
const Calendar: React.FC<CalendarProps> = ({ state, ...props }) => {
  /*
   * To make sure we get session counts for all the dates visible
   * in the calendar we roll back the start date from the start of the month
   * to the start of that week, and roll forward the end date to the end of the week
   * of the last day of the month.
   */

  // Keeping this in to test the bug
  // const startRange = u.time.startOfWeek(
  //   u.time.startOfMonth(state.filters.values.from)
  // );

  const endRange = u.time.endOfWeek(
    u.time.endOfMonth(state.filters.values.from)
  );

  const { data, fetchMore, loading } = T.useSessionsFeedCalendarQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      ...h.getQueryVariables(state.sections.enabled, state.filters.values, {}),
      limit: 50,
      from: state.filters.values.from,
    },
  });

  const calenderFilter = { ...state.filters.values };

  const signedUpCounts = h.signedUpCounts(data);

  const sessionCounts = h.sessionCounts(data, calenderFilter);

  const cursors = h.cursors(data);

  React.useEffect(() => {
    if (!data || loading) return;

    const moreSessions =
      data.sessions.more &&
      cursors.sessions &&
      u.time.isBefore(cursors.sessions.startedAt, endRange);

    const moreTaskRequests =
      data.taskRequests.more &&
      cursors.taskRequests &&
      u.time.isBefore(cursors.taskRequests.potentialTimes[0], endRange);

    if (moreSessions || moreTaskRequests) {
      setTimeout(
        () =>
          fetchMore({
            variables: {
              ...h.getQueryVariables(
                state.sections.enabled,
                state.filters.values,
                cursors
              ),
              limit: 30,
              from: state.filters.values.from,
            },
          }),
        200
      );
    }
  }, [data, fetchMore,loading, cursors, state, endRange, state.filters.values]);

  const css = useStyles();

  const tileContent = (props: CalendarTileProperties) => {
    const signedUpCount = signedUpCounts[u.time.formatDate(props.date)] || 0;
    const sessionCount = sessionCounts[u.time.formatDate(props.date)] || 0;
    const redDots = Math.min(signedUpCount, 2);
    const blueDots = Math.max(Math.min(sessionCount, 2 - signedUpCount), 0);

    return (
      <UI.Tooltip title={h.tileTooltip(signedUpCount)}>
        <UI.Box sx={{ position: "relative", height: "100%" }}>
          <UI.Box
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 2,
              textAlign: "center",
            }}
          >
            <UI.Box className="react-calendar__dot">
              <UI.Box component="span" sx={{ color: "primary.main" }}>
                {_.repeat("• ", redDots)}
              </UI.Box>
              <UI.Box component="span" sx={{ color: "neutral.dark" }}>
                {_.repeat("• ", blueDots)}
              </UI.Box>
            </UI.Box>
          </UI.Box>
        </UI.Box>
      </UI.Tooltip>
    );
  };

  return (
    <UI.Card {...props} css={css}>
      <UI.CardContent sx={{ pb: 0 }}>
        <ReactCalendar
          maxDetail="month"
          minDetail="month"
          next2Label={null}
          prev2Label={null}
          nextLabel={<Icons.ArrowForward />}
          prevLabel={<Icons.ArrowBack />}
          value={state.filters.values.from}
          onChange={(from: Date) => state.filters.update({ from })}
          activeStartDate={state.filters.values.from}
          onActiveStartDateChange={({ activeStartDate }) =>
            state.filters.update({ from: activeStartDate })
          }
          tileContent={tileContent}
        />

        <UI.Box sx={{ mt: 2 }}>
          <UI.Button
            color="neutral"
            variant="outlined"
            fullWidth
            onClick={() => {
              state.filters.update({
                from: u.time.startOfDay(new Date()),
              });
            }}
          >
            Today
          </UI.Button>
        </UI.Box>
      </UI.CardContent>
    </UI.Card>
  );
};

export default Calendar;
