import * as T from "@goodgym/graphql/types";
import _ from "lodash";
import * as u from "@goodgym/util";
import { getStartTime } from "../../helpers";
import { Filters } from "../../types";
import * as helper from "../../helpers";

export { getQueryVariables } from "../../helpers";

export const flattenTaskRequest = (
  tr: Pick<T.TaskRequestFragment, "id" | "potentialTimes">
) => {
  const timesByDay = _.groupBy(tr.potentialTimes, (t) =>
    u.time.startOfDay(t).getTime()
  );
  return _.map(timesByDay, (potentialTimes, _day) => ({
    ...tr,
    potentialTimes: u.time.sort(potentialTimes),
  }));
};

export const signedUpCounts = (data?: T.SessionsFeedCalendarQuery) => {
  const sessions = data?.runner?.upcomingSessions || [];
  const dates = _.map(sessions, (s) => u.time.formatDate(s.startedAt));
  return u.frequencies(dates);
};

export const sessionCounts = (
  data?: T.SessionsFeedCalendarQuery,
  calendarFilter?: Filters
) => {
  const sessions = data?.sessions.results;

  const calendarData = calendarFilter
    ? _.filter(sessions, (item) => helper.applyFilters(calendarFilter, item))
    : sessions;

  const taskRequests = data?.taskRequests.results
    .filter((tr) => !tr.declined)
    .flatMap(flattenTaskRequest);

  const items = [...(calendarData || []), ...(taskRequests || [])];

  const dates = _.map(items, (s) => u.time.formatDate(getStartTime(s)));
  return u.frequencies(dates);
};

/*
 * Unlike the SessionsFeed, we don't have to do anything clever
 * when calculating the cursors for the calendar query, we just
 * use the last session and the last taskrequest. This is because
 * the calendar doesn't show details of sessions, just a dot, so
 * sessions arriving out of order doesn't matter.
 */
export const cursors = (data?: T.SessionsFeedCalendarQuery) => {
  return {
    sessions: _.last(data?.sessions.results),
    taskRequests: _.last(data?.taskRequests.results),
  };
};

export const tileTooltip = (signedUpCount: number) => {
  if (signedUpCount === 0) return "";
  return `You're signed up to ${signedUpCount} ${u.inflect(
    "session",
    signedUpCount
  )} on this day`;
};
