import * as UI from "@goodgym/components";
import * as menus from "@goodgym/menus";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { State } from "../../../types";
import { SelectChangeEvent } from "@mui/material";  // Import the correct event type

export type SessionTypesFilterProps = UI.BoxProps & {
  state: Pick<State, "sections" | "filters" | "availableTypes" | "drawers">;
};

// Helper to check if localStorage is available
const isLocalStorageAvailable = (): boolean => {
  try {
    const test = "test";
    if (typeof window !== "undefined" && window.localStorage) {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    }
  } catch (e) {
    console.warn("localStorage is not available:", e);
  }
  return false;
};

const SessionTypesFilter: React.FC<SessionTypesFilterProps> = ({
  state,
  ...props
}) => {
  const { filters } = state;

  // Safely access localStorage
  const getSessionTypeFromStorage = () => {
    if (isLocalStorageAvailable()) {
      return localStorage.getItem("sessionType");
    }
    return null;
  };

  const [typeSelected, setTypeSelected] = React.useState(() => {
    const sessionType = getSessionTypeFromStorage();
    return sessionType ? sessionType : "all";
  });

  React.useEffect(() => {
    const handleBeforeUnload = () => {
      if (isLocalStorageAvailable()) {
        localStorage.clear(); // Clear localStorage only on page refresh
      }
    };

    // Retrieve sessionType from localStorage
    const sessionType = getSessionTypeFromStorage();

    const parsedSessionType =
      sessionType && sessionType !== "all" && sessionType !== "Group Sessions"
        ? sessionType
        : null;

    if (parsedSessionType !== null) {
      filters.update({
        types: [parsedSessionType],
      });
      setTypeSelected(parsedSessionType);
    } else {
      filters.update({
        types: [
          "GroupRun",
          "CommunityMission",
          "Mission",
          "DocumentCheck",
          "Party",
          "TrainingSession",
          "Race",
        ],
      });
    }

    // Add event listener for page unload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSessionTypeChange = (event: SelectChangeEvent) => {
    const value = (): Array<string> => {
      switch (event.target.value) {
        case "all":
          return [
            "GroupRun",
            "CommunityMission",
            "Mission",
            "DocumentCheck",
            "Party",
            "TrainingSession",
            "Race",
          ];
        case "Group Sessions":
          return ["GroupRun", "CommunityMission"];
        default:
          return [event.target.value];
      }
    };
    filters.update({
      types: value(),
    });

    const newType = event.target.value as string;
    if (isLocalStorageAvailable()) {
      try {
        localStorage.setItem("sessionType", newType);
      } catch (e) {
        console.warn("Error saving to localStorage:", e);
      }
    }

    setTypeSelected(newType);
  };

  return (
    <UI.Box {...props}>
      <UI.Typography component={"span"} variant="subtitle1">
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1, mb: 2 }}>
            Session types
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>
      <UI.Box>
        <FormControl fullWidth>
          <UI.InputLabel> Sessions Type</UI.InputLabel>
          <UI.Select
            id="session-type-filter"
            label="Sessions Type"
            onChange={handleSessionTypeChange}  // Correctly typed event handler
            value={typeSelected}
          >
            <UI.MenuItem value="all">All Session Types</UI.MenuItem>
            <UI.MenuItem value="Group Sessions">Group Sessions</UI.MenuItem>
            
            {menus.sessions.slice(2).map(({ type }) => (
              <UI.MenuItem key={type} value={type}>
                {type.replace(/([A-Z])/g, " $1").replace(/^\s/, "")}
              </UI.MenuItem>
            ))}
          </UI.Select>
        </FormControl>
      </UI.Box>
    </UI.Box>
  );
};

export default SessionTypesFilter;
