/** @jsxImportSource @emotion/react */
import React from "react";

import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as h from "./helpers";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";
import { useDrawers } from "@goodgym/drawers";

export type SessionCardProps = UI.CardProps & {
  session: T.SessionCardFragment
  dashboard?: boolean;
};

const SessionCard: React.FC<SessionCardProps> = ({
  session,
  dashboard,
  ...props
}) => {
  const { runner } = useAuth();
  const { showSession } = useDrawers();

  console.log("report", session.report)
  const link = session.report
    ? u.links.report(session.report)
    : u.links.session(session);

  return (
    <UI.Card
      {...props}
      sx={{
        display: "flex",
        flexDirection: dashboard
          ? "column-reverse"
          : { xs: "column-reverse", md: "row" },
        height: "fit-content",
        mt: 1,
      }}
    >
      <UI.Box sx={{ width: { sm: "100%" } }}>
        <UI.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            px: 2,
            height: "100%",
          }}
        >
          <UI.Box>
            <UI.Box
              sx={{
                background: "black",
                width: "fit-content",
                my: 1,
                boxShadow: "-5px 5px 0 -1px #E11018",
              }}
            >
              <UI.Typography
                sx={{
                  mb: 0.5,
                  px: 1,
                  fontStyle: "normal",
                  fontFamily: "'Barlow Condensed', sans-serif",
                  textTransform: "uppercase",
                }}
                color="common.white"
              >
                {session.area?.name}
              </UI.Typography>
            </UI.Box>

            <UI.Link
              href={link}
              onClick={(e) => showSession(e, session)}
              variant="h4"
              sx={{ color: "main", flexWrap: "wrap" }}
            >
              {session.title}
            </UI.Link>

            <UI.Typography component="div" variant="body1" sx={{ mt: 1 }}>
              <UI.Box sx={{ mb: 1 }}>
                <UI.SessionTypeChip session={session} />
              </UI.Box>
              <UI.Box component="span" sx={{ mr: 1, whiteSpace: "nowrap" }}>
                <UI.Emoji emoji="calendar" sx={{ mr: 1 }} />
                {u.time.formatDay(session.startedAt)}{" "}
                <UI.Box color="neutral.dark" component="span">
                  {u.time.formatTime(session.startedAt)}
                </UI.Box>
              </UI.Box>
            </UI.Typography>
            {session.address && (
              <>
                <UI.Typography variant="body1" sx={{ mt: 1, display: "flex" }}>
                  <UI.Emoji emoji="mapPin" sx={{ mr: 0 }} />
                  <UI.Box component="span">
                    {session.address.line1}{" "}
                    <UI.Box component="span" sx={{ whiteSpace: "nowrap" }}>
                      {session.address.postcode}
                    </UI.Box>
                  </UI.Box>
                </UI.Typography>
              </>
            )}
            <UI.Typography variant="body2" color="black" mt={1} sx={{ px: 1 }}>
              {session.strapline}
            </UI.Typography>
          </UI.Box>
          <UI.Box
            sx={{
              mb: 1,
            }}
          >
            {(session.signups.length > 0 || session.registerMax) && (
              <UI.Grid container sx={{ mt: 0 }}>
                <UI.Grid item xs={12}>
                  <UI.AvatarGroup
                    max={6}
                    sx={{
                      justifyContent: "left",
                      mt: 1,
                      mb: "-2px",
                    }}
                  >
                    {session.signups.map((runner) => (
                      <UI.RunnerAvatar
                        key={runner.id}
                        runner={runner}
                        noStatus
                      />
                    ))}
                  </UI.AvatarGroup>
                </UI.Grid>
                <UI.Grid item xs={12}>
                  <UI.Typography variant="caption" sx={{ mt: 1 }}>
                    {h.signupStatus(session, runner)}
                  </UI.Typography>
                </UI.Grid>
              </UI.Grid>
            )}
          </UI.Box>
        </UI.Box>
      </UI.Box>
      <UI.Box
        sx={{
          width: { sm: "100%", md: dashboard ? "100%" : "50%" },
          overflow: "hidden",
          maxHeight:{xs:"350px", md:"400px"}
        }}
      >
        <UI.Box
          component="img"
          sx={{
            width: dashboard ? "50vw" : "100%",
            height: dashboard ? "280px" : "100%",
            minHeight: {xs:"200px", md:"300px"},
            objectFit: "cover",
          }}
          onClick={(event: any) => showSession(event, session)}
          src={session.imageUrl}
        />
      </UI.Box>
    </UI.Card>
  );
};

// @ts-ignore
SessionCard.muiName = UI.Card.muiName;

export default SessionCard;
