/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import GoogleMap from "google-map-react";
import { State } from "../../types";
import * as h from "../../helpers";

export type MapProps = UI.BoxProps & {
  state: Pick<State, "items" | "mapPeriod" | "setMapPeriod" | "more">;
};

const Map: React.FC<MapProps> = ({ state, ...props }) => {
  const [selectedListing, setSelectedListing] = React.useState<string>();
  const { items, more } = h.filterForMap(state);
  const defaultCenter = u.geo.defaultCenter(items);

  const itemsByLocation = React.useMemo(
    () =>
      _.groupBy(items, (i) => {
        const center = u.geo.center(i);
        return `${center.lat},${center.lng}`;
      }),
    [items]
  );

  React.useEffect(() => {
    // if we've selected a map pin and then applied a filter
    // that excludes its sessions, unselect it
    if (selectedListing && !itemsByLocation[selectedListing]) {
      setSelectedListing(undefined);
    }
  }, [selectedListing, setSelectedListing, itemsByLocation]);

  if (more) return <UI.Loading text="Loading sessions" />;

  return (
    defaultCenter && (
      <>
        <UI.ToggleButtonGroup
          sx={{
            d: {
              xs: "block",
              md: "none",
            },
            position: "absolute",
            backgroundColor: "neutral.light",
            top: 8,
            left: 8,
            zIndex: 1,
          }}
          color="secondary"
          size="small"
          exclusive
          value={state.mapPeriod}
          onChange={(_, period) => period && state.setMapPeriod(period)}
        >
          <UI.ToggleButton value="DAY">Day</UI.ToggleButton>
          <UI.ToggleButton value="WEEK">Week</UI.ToggleButton>
          <UI.ToggleButton value="MONTH">Month</UI.ToggleButton>
        </UI.ToggleButtonGroup>
        {selectedListing && itemsByLocation[selectedListing] && (
          <UI.ListingCarousel
            key={selectedListing}
            onClose={() => setSelectedListing(undefined)}
            sx={{
              position: { xs: "fixed", md: "absolute" },
              bottom: { xs: 64, md: 8 },
              left: 8,
              right: 8,
              zIndex: 1,
            }}
            listings={itemsByLocation[selectedListing]}
          />
        )}
        <GoogleMap
          key={state.mapPeriod}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
          }}
          defaultZoom={15}
          center={defaultCenter}
          style={{ height: "100%", width: "100%" }}
          yesIWantToUseGoogleMapApiInternals
          onChildClick={(_, { lat, lng }) => {
            setSelectedListing(`${lat},${lng}`);
          }}
          onGoogleApiLoaded={({ map }) => {
            map?.fitBounds(u.geo.defaultBounds(items));
            map?.setZoom(Math.min(map?.getZoom(), 15));
          }}
        >
          {_.map(itemsByLocation, (items, k) => {
            const center = u.geo.center(items[0]);
            return (
              <UI.ListingMapMarker
                key={k}
                listings={items}
                selected={k === selectedListing}
                lat={center.lat!}
                lng={center.lng!}
              />
            );
          })}
        </GoogleMap>
      </>
    )
  );
};

export default Map;
