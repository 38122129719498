import * as UI from "@goodgym/components";
import PostcodeSearch from "../../../components/PostcodeSearch";
import * as Images from "../../images";
import { css } from "@emotion/react";
import * as T from "@goodgym/graphql/types";

const MidBanner = () => {
  const { data } = T.useHomePageQuery();

  const numberOfCoachRuns = data?.stats.socialvisits;

  return (
    <>
      <UI.Box
        sx={{
          bgcolor: "black.main",
          minHeight: "400px",
          textAlign: "center",
          position: "relative",
          pt: 3,
          mt: 9,
          pb: 9,
          px: 1,
        }}
        css={css`
          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 5rem;
            background-image: linear-gradient(
              to bottom left,
              #f5f5f5,
              #f5f5f5 50%,
              #333 51%,
              #333
            );
            position: absolute;
            top: -5rem;
            left: 0;
            z-index: 100; // Ensures it stays behind the footer content
          }
        `}
      >
        <img
          src={Images.Badge}
          alt="badge"
          style={{ height: "160px", width: "auto" }}
        />
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textTransform: "uppercase", fontColor: "white" }}
        >
          {numberOfCoachRuns?.toLocaleString()}
        </UI.Typography>
        <UI.Typography
          variant="h1"
          color="neutral.light"
          sx={{ textTransform: "uppercase" }}
        >
          Social visits
        </UI.Typography>

        <PostcodeSearch />
        <UI.Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UI.Typography variant="h3" color={"neutral.light"} sx={{ mr: 1 }}>
            ||
          </UI.Typography>
          <UI.Typography
            variant="subtitle1"
            color="neutral.light"
            sx={{ fontStyle: "italic", my: 1 }}
          >
            We get on very well, she is such a wonderful person who would go out
            of her way to help.  We get on fabulously together.
          </UI.Typography>
        </UI.Container>
        <UI.Typography
          variant="subtitle1"
          color="neutral.light"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: "200px",
            mt: 3,
          }}
        >
          Mrs S from W&M
        </UI.Typography>
      </UI.Box>
    </>
  );
};

export default MidBanner;
