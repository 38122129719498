/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import React from "react";
import { State } from "../../../types";

export type PostcodeFilterProps = UI.BoxProps & {
  state: Pick<State, "sections" | "filters">;
};

const PostcodeFilter: React.FC<PostcodeFilterProps> = ({ state, ...props }) => {
  const { sections, filters } = state;

  // we have our own internal state to keep track of the postcode
  // as it's being entered, such that we only ever put valid postcodes
  // into the state
  const [postcode, setPostcode] = React.useState(
    filters.values.postcode || ""
  );

  const enabled = sections.enabled.postcode;

  const updatePostcode = () => {
    const formatted = u.format.postcode(postcode);
    if (u.validate.postcode(formatted)) {
      filters.update({ postcode: formatted });
      setPostcode(formatted);
    }
  };

  const helperText =
    !postcode || u.validate.postcode(u.format.postcode(postcode))
      ? ""
      : `Invalid postcode "${postcode}"`;

  return (
    <UI.Box {...props}>
      <UI.Typography variant="subtitle1" sx={{ mb: 1 }}>
        <UI.Grid container>
          <UI.Grid item sx={{ alignSelf: "center", flexGrow: 1 }}>
            Postcode
          </UI.Grid>
        </UI.Grid>
      </UI.Typography>
      <UI.TextField
        fullWidth
        disabled={!enabled}
        placeholder="e.g. WC2R 1LA"
        type="text"
        value={postcode}
        error={!!postcode && !u.validate.postcode(u.format.postcode(postcode))}
        onChange={(e) => setPostcode(e.target.value)}
        helperText={helperText}
        FormHelperTextProps={{ sx: { ml: 0 } }}
        onKeyPress={(e) => e.key === "Enter" && updatePostcode()}
        onBlur={updatePostcode}
      />
      <UI.Box>
        <UI.Button
          size="small"
          color="secondary"
          onClick={() => {
            updatePostcode();
          }}
          sx={{ alignSelf: "center", mt: 1 }}
        >
          Update
        </UI.Button>
      </UI.Box>
    </UI.Box>
  );
};

export default PostcodeFilter;
