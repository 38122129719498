/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import _ from "lodash";
import React from "react";
import { Calendar, Filters, Map, ResultCount } from "./components";
import * as h from "./helpers";
import { State } from "./types";
import InfiniteScroll from "react-infinite-scroller";
import { useTheme } from "@goodgym/components";

export type SessionsFeedProps = UI.BoxProps & {
  state: State;
};

const SessionsFeed: React.FC<SessionsFeedProps> = ({ state, ...props }) => {
  const { loading, more, fetchMore, items } = state;
  const [loadingMore, setLoadingMore] = React.useState(false);

  const theme = useTheme();
  const md = UI.useMediaQuery(theme.breakpoints.up("md"));

  const handleLoadMore = async () => {
    if (!loadingMore && more) {
      setLoadingMore(true);
      await fetchMore();
      setLoadingMore(false);
    }
  };

  const Feed = () => (
    <>
      {_.map(h.groupByDay(items), ([date, items]) => (
        <UI.Box key={date.getTime()} sx={{ pt: 1, pb: 2 }}>
          <UI.Typography variant="h5" color="neutral.main">
            {u.time.formatDay(date)}
          </UI.Typography>
          {items.map((item) => (
            <UI.Box key={item.id} sx={{ mb: 2 }}>
              <UI.ListingCard
                key={h.identifier(item)}
                listing={item}
                sx={{ my: 2 }}
              />
            </UI.Box>
          ))}
        </UI.Box>
      ))}
    </>
  );

  return (
    <>
      {loading ? (
        <UI.Loading key="loading" />
      ) : (
        <>
          {items.length === 0 && (
            <UI.Box key="empty">
              <UI.Typography variant="h5" color="neutral.main">
                No sessions found 🤔
              </UI.Typography>
              <UI.Typography variant="body1" color="neutral.main">
                Try removing or changing some of your filters
              </UI.Typography>
            </UI.Box>
          )}
          {!md && (
            <InfiniteScroll
              hasMore={more}
              loadMore={fetchMore}
              initialLoad={false}
              loader={<UI.InfiniteScrollLoader key="loader" more={more} />}
            >
              <Feed />
            </InfiniteScroll>
          )}

          {md && (
            <>
              <Feed />
            </>
          )}

          {/* Load More Button */}
          {more && md && (
            <UI.Box sx={{ textAlign: "center", mt: 2 }}>
              <UI.Button
                variant="text"
                onClick={handleLoadMore}
                disabled={loadingMore}
                sx={{
                  fontSize: "30px",
                  textTransform: "uppercase",
                  fontStyle: "italic",
                }}
              >
                {loadingMore ? "Loading..." : "Load More"}
              </UI.Button>
            </UI.Box>
          )}
        </>
      )}
    </>
  );
};

//@ts-ignore
SessionsFeed.Calendar = Calendar;
//@ts-ignore
SessionsFeed.Map = Map;
//@ts-ignore
SessionsFeed.ResultCount = ResultCount;
//@ts-ignore
SessionsFeed.Filters = Filters;

export default SessionsFeed as typeof SessionsFeed & {
  Calendar: typeof Calendar;
  Map: typeof Map;
  Filters: typeof Filters;
  ResultCount: typeof ResultCount;
};
