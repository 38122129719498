/** @jsxImportSource @emotion/react */
import React from "react";
import * as UI from "@goodgym/components";
import * as u from "@goodgym/util";
import _ from "lodash";
import * as Social from "react-share";

export type SocialShareButtonsProps = UI.TypographyProps & {
  id: string;
  type: u.analytics.ShareableType;
  title: string;
  url: string;
  imageUrl: string;
  cta: string;
};

const SocialShareButtons: React.FC<
  React.PropsWithChildren<SocialShareButtonsProps>
> = ({ id, type, title, url, imageUrl, cta, ...props }) => {
  const track = (socialType: u.analytics.SocialType) => {
    u.analytics.track("share", {
      shareable_id: id,
      shareable_type: type,
      social_type: socialType,
    });
  };

  return (
    <UI.Grid container spacing={1}>
      <UI.Grid item>
        <Social.EmailShareButton
          subject={title}
          body={cta}
          separator=" - "
          url={url}
          beforeOnClick={() => track("Email")}
        >
          <Social.EmailIcon size={32} round />
        </Social.EmailShareButton>
      </UI.Grid>
      <UI.Grid item>
        <Social.TwitterShareButton
          title={title}
          url={url}
          hashtags={["GoodGym", "DoGoodGetFit"]}
          beforeOnClick={() => track("Twitter")}
        >
          <Social.TwitterIcon size={32} round />
        </Social.TwitterShareButton>
      </UI.Grid>
      <UI.Grid item>
        <Social.FacebookShareButton
          quote={title}
          url={url}
          hashtag="DoGoodGetFit"
          beforeOnClick={() => track("Facebook")}
        >
          <Social.FacebookIcon size={32} round />
        </Social.FacebookShareButton>
      </UI.Grid>
      <UI.Grid item>
        <Social.PinterestShareButton
          description={title}
          url={url}
          media={imageUrl}
          beforeOnClick={() => track("Pinterest")}
        >
          <Social.PinterestIcon size={32} round />
        </Social.PinterestShareButton>
      </UI.Grid>
      <UI.Grid item>
        <Social.WhatsappShareButton url={url} title={title} separator=":: ">
          <Social.WhatsappIcon size={32} round />
        </Social.WhatsappShareButton>
      </UI.Grid>
      <UI.Grid item>
        <Social.FacebookMessengerShareButton
          appId={process.env.REACT_APP_FACEBOOK_APP_ID!}
          title={title}
          url={url}
          beforeOnClick={() => track("Messenger")}
        >
          <Social.FacebookMessengerIcon size={32} round />
        </Social.FacebookMessengerShareButton>
      </UI.Grid>
    </UI.Grid>
  );
};

export default SocialShareButtons;
