import { useAuth } from "@goodgym/auth";
import * as T from "@goodgym/graphql/types";
import { useDrawer } from "@goodgym/hooks";
import * as menus from "@goodgym/menus";
import * as u from "@goodgym/util";
import _ from "lodash";
import React from "react";
import * as h from "./helpers";
import { EnabledSections, Filters} from "./types";

/*
 * The useSessionsFeed hook allows us to pass in an area, filters, or enabled
 * sections to override the defaults applied to the filter. This enables the
 * component to be reused on different pages with different needs. For example,
 * on the main sessions listings page /v3/sessions, we store the user's last
 * used filters in localStorage, so we retrieve and pass those in. On the
 * other hand, on the area page we want to override the user's area to show
 * only sessions in the page the area is for, regardless of the area of the
 * user looking at it.
 */
export const useSessionsFeed = (
  props: {
    area?: T.AreaFragment;
    filters?: Partial<Filters>;
    type?: string[];
    sections?: Partial<EnabledSections>;
    postcode?: string;
  } = {}
): any => {
  const { runner } = useAuth();

  const [fetchSessions, { error, data }] = T.useSessionsFeedLazyQuery({
    fetchPolicy: "cache-and-network",
  });


  // State for managing the currently selected filters and the drawer
  // for showing them.
  const filtersDrawer = useDrawer();

  const defaultFilters = {
    from: u.time.startOfDay(new Date()),

    types: props.type
      ? props.type
      : _.without(
          // exclude doc checks from the filters by default
          // as you'll only need to see them once every three years max
          menus.sessions.map((s) => s.type),
          "DocumentCheck"
        ),

    // if we're passed an area this overrides the runners areas,
    // and we also ignore the runner's postcode - we're likely on
    // a page specifically for that area
    areaIds: props.area
      ? [props.area.id]
      : runner?.areas.map((a) => a.id) || [],
    postcode: props.postcode ? props.postcode : runner?.postcode || null,

    maxDistance: 5,
    onlyAvailable: false,
    onlySignedUp: false,
    includeDeclined: false,
    dayOfWeek: [],
    timeOfDay: [],
  };

  const defaultSections = {
    areas: true,
    postcode: !!props.area,
    types: true,
  };

  const [filters, setFilters] = React.useState<Filters>({
    ...defaultFilters,
    ...props.filters,
  });

  const [sections, setSections] = React.useState<EnabledSections>({
    ...defaultSections,
    ...props.sections,
  });

  const updateFilters = (update: Partial<Filters>) => {
    setFilters({ ...filters, ...update });
  };

  const toggleSection = (section: keyof EnabledSections) => {
    // setSections({ ...sections, [section]: !sections[section] });
    if(section === "areas") {
      setSections({ ...sections, areas: true, postcode: false });
    } else {
      setSections({ ...sections, areas: false, postcode: true });
    }
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setSections(defaultSections);
  };

  // state for managing whether we're showing the map, and what period
  // we're showing it for
  // const [view, setView] = React.useState<State["view"]>("LIST_VIEW");
  // const [mapPeriod, setMapPeriod] = React.useState<State["mapPeriod"]>("WEEK");

  // state for managing the subset of areas we show as checkboxes on the
  // area filter panel
  const [areas, setAreas] = React.useState<T.AreaFragment[]>(
    runner?.areas || []
  );

  const addArea = (area: T.AreaFragment) => {
    setAreas(_.union(areas, [area]));
  };

  // handle the horrors of pagination
  const { items, cursors, more } = h.collate(data);

  // function for the InfiniteScroll component
  const fetchMore = React.useCallback(() => {
    if (more) {
      fetchSessions({
        variables: {
          ...h.getQueryVariables(sections, filters, cursors),
        },
      });
    }
  }, [fetchSessions, cursors, filters, more, sections]);

  // refetch sessions every time one of the filters is changed or a filter
  // section is enabled or disabled
  React.useEffect(() => {
    fetchSessions({
      variables: h.getQueryVariables(sections, filters, {}),
    });
  }, [filters, sections, fetchSessions]);


  return {
    runner,
    error,
    loading: !data,
    items: _.filter(items, (item) => h.applyFilters(filters, item)),
    more,
    fetchMore,
    drawers: { filters: filtersDrawer },
    filters: { values: filters, update: updateFilters, reset: resetFilters },
    availableTypes: h.getAvailableTypes(items),
    sections: {
      enabled: sections,
      toggle: toggleSection,
    },
    areas: { options: areas, add: addArea },
  };
};
