/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";

export type PostcodeSearchProps = UI.BoxProps & { fullWidth?: boolean };

const PostcodeSearch: React.FC<PostcodeSearchProps> = ({
  fullWidth = false,
  ...props
}) => {
  const [postcode, setPostcode] = React.useState("");
  return (
    <UI.Box {...props}>
      <UI.Typography
        variant="h5"
        component="h2"
        color="white.main"
        sx={{ my: 2 }}
      >
        Find sessions in your local area and start doing good today.
      </UI.Typography>
      <form action="/happenings" method="get">
        <UI.TextField
          name="postcode"
          placeholder="Enter your postcode"
          variant="outlined"
          onChange={(e) => setPostcode(e.target.value?.toUpperCase())}
          value={postcode}
          fullWidth={fullWidth}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <UI.InputAdornment position="end">
                <UI.IconButton
                  edge="end"
                  color="primary"
                  type="submit"
                  aria-label="Search for sessions"
                >
                  <Icons.ArrowForward />
                </UI.IconButton>
              </UI.InputAdornment>
            ),
          }}
        />
      </form>
    </UI.Box>
  );
};

export default PostcodeSearch;
