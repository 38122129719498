import { css } from "@emotion/react";
import * as UI from "@goodgym/components";
import { lighten } from "polished";

export const useStyles = () => {
  const theme = UI.useTheme();
  return css`
    .react-calendar {
      width: 100%;
      max-width: 100%;
      background: ${theme.palette.background.paper};

      button {
        margin: 0;
        border: 0;
        outline: none;

        &:enabled {
          &:hover {
            cursor: pointer;
          }
        }
      }

      &__navigation {
        display: flex;
        margin-bottom: ${theme.spacing(1)};

        &__label {
          font-family: ${theme.typography.caption.fontFamily};
          font-size: ${theme.typography.caption.fontSize}px;
          font-weight: ${theme.typography.caption.fontWeight};
          line-height: ${theme.typography.caption.lineHeight};
          color: ${theme.palette.neutral.dark};
          text-transform: uppercase;
        }

        &__arrow {
          color: ${theme.palette.neutral.dark};
          font-size: ${theme.typography.body1.fontSize}px;
        }

        &__prev-button {
          text-align: left;
          padding-left: 0;
        }

        &__next-button {
          text-align: right;
          padding-right: 0;
        }

        button {
          min-width: 44px;
          background: none;

          &:enabled {
            &:hover {
              color: ${theme.palette.black.main};
            }
          }
        }
      }

      &__month-view {
        &__weekdays {
          text-align: center;
          font-family: ${theme.typography.caption.fontFamily};
          font-weight: ${theme.typography.caption.fontWeight};
          text-transform: ${theme.typography.caption.textTransform};
          font-size: ${theme.typography.caption.fontSize}px;
          line-height: ${theme.typography.caption.lineHeight};
          color: ${theme.palette.neutral.dark};
          abbr {
            text-decoration: none;
          }

          &__weekday {
            padding: ${theme.spacing(1)};
          }
        }

        &__days {
          border-top: 1px solid #c2c2c2 !important;
          border-left: 1px solid #c2c2c2 !important;
          &__day {
            font-family: ${theme.typography.subtitle1.fontFamily};
            font-weight: ${theme.typography.subtitle1.fontWeight};
            text-transform: ${theme.typography.subtitle1.textTransform};
            font-size: ${theme.typography.subtitle1.fontSize}px;
            line-height: ${theme.typography.subtitle1.lineHeight};
            color: ${theme.palette.neutral.main};
            &--weekend {
              background: ${lighten(
                0.18,
                theme.palette.secondary.main
              )} !important;
              &:hover {
                background-color: ${lighten(
                  0.1,
                  theme.palette.secondary.main
                )} !important;
              }
            }
            &--neighboringMonth {
              background: ${theme.palette.neutral.light} !important;
              &:hover {
                background: rgb(230, 230, 230) !important;
              }
            }
          }
        }
      }

      &__year-view,
      &__decade-view,
      &__century-view {
        .react-calendar__tile {
          padding: 2em 0.5em;
        }
      }

      &__tile {
        border-right: 1px solid #c2c2c2 !important;
        border-bottom: 1px solid #c2c2c2 !important;
        max-width: 100%;
        padding: ${theme.spacing(2)} 6.6667px;
        background: none;
        text-align: center;
        line-height: 16px;

        &:disabled {
          background-color: rgb(240, 240, 240);
        }

        &:enabled {
          &:hover,
          &:focus {
            background-color: rgb(230, 230, 230);
          }
        }

        &--active {
          background: ${theme.palette.secondary.main} !important;
          color: ${theme.palette.black.main} !important;
          .react-calendar__dot {
            color: ${theme.palette.black.main} !important;
          }

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, theme.palette.secondary.main)};
            }
          }
        }
      }

      &--selectRange {
        .react-calendar__tile {
          &--hover {
            background-color: rgb(230, 230, 230);
          }
        }
      }
    }
  `;
};
