/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as Layouts from "@goodgym/layouts";
import * as Pages from "@goodgym/pages";
import * as u from "@goodgym/util";
import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

const App = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    u.analytics.page(pathname);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Pages.Home />} />
      <Route path="/v3/" element={<Layouts.Default clientOnly />}>
        <Route path="" element={<Pages.Dashboard />} />
        <Route path="sessions" element={<Pages.Sessions />} />
        <Route path="social-visits" element={<Pages.SocialVisitsPage />} />
        <Route
          path="about-social-visits"
          element={<Pages.AboutSocialVisitPage />}
        />
        <Route path="about-missions" element={<Pages.AboutMissionPage />} />
        <Route path="about-group-runs" element={<Pages.AboutGroupRunsPage />} />
        <Route path="about-community-missions" element={<Pages.AboutCommunityMissionPage />} />
        <Route path="reports" element={<Pages.Reports />} />
        <Route path="stories" element={<Pages.Stories />} />
        <Route path="stories/:slug" element={<Pages.Story />} />
        <Route path="stories/drafted" element={<Pages.DraftedStories />} />
        <Route path="stories/deleted" element={<Pages.DeletedStories />} />
        <Route path="stories/:section/:slug" element={<Pages.Story />} />
        <Route path="ops-support" element={<Pages.OpsSupport />} />
        <Route path="opops" element={<Navigate to="/v3/ops-support" />} />
        <Route path="styleguide" element={<Pages.StyleGuide />} />
      </Route>
      <Route path="/v3/" element={<Layouts.Default />}>
        <Route path="conduct" element={<Pages.CodeOfConduct />} />
        <Route path="areas/:slug" element={<Pages.Area />} />
        <Route path="areas/:slug/:section" element={<Pages.Area />} />
        <Route path="areas/:slug/:section/:type" element={<Pages.Area />} />
        <Route path="organisations/:slug" element={<Pages.Organisation />} />
        <Route path="privacy" element={<Pages.PrivacyPolicy />} />
        <Route path="reports/:slug" element={<Pages.Report />} />
        <Route path="profile/:slug" element={<Pages.Profile />} />
        <Route path="profile/:slug/my-team" element={<Pages.MyTeamPage />} />
        <Route path="request-a-task" element={<Pages.RequestPage />} />
        <Route path="sessions/:slug" element={<Pages.Session />} />
        <Route path="social-visits/:id" element={<Pages.SocialVisitPage />} />
        <Route path="trial-visits" element={<Pages.TestPairingDashboard />} />
        <Route path="trial-visits/:id" element={<Pages.TestPairingPage />} />
        <Route path="about" element={<Pages.AboutPage />} />
        <Route path="more" element={<Pages.More />} />
        <Route path="contact" element={<Pages.ContactPage />} />
        <Route path="*" element={<UI.NotFound />} />
        <Route path="request-a-mission" element={<Pages.RequestMission />} />
        <Route
          path="campaigns/power-the-good"
          element={<Pages.PowerTheGood />}
        />
      </Route>
      <Route
        path="powerthegood"
        element={<Navigate to="/v3/campaigns/power-the-good" />}
      />
      <Route path="/my" element={<Navigate to="/v3" />} />
    </Routes>
  );
};

export default App;
