import * as UI from "@goodgym/components";
import { State } from "@goodgym/components/SessionsFeed/types";
import React from "react";
import type { DrawerView } from "../..";
import ShowSocialVisitsLink from "../Header/ShowSocialVisitsLink"

export type FilterDrawerProps = UI.BoxProps & {
  state: State;
  drawerView: DrawerView;
};

const Drawer: React.FC<React.PropsWithChildren<FilterDrawerProps>> = ({
  state,
  drawerView
}) => {
  
  const { drawers } = state;
  let title = "Filters";
  let content: React.ReactNode = null;

  if (drawerView === "CALENDAR_VIEW") {
    title = "Calendar";
    content = <UI.SessionsFeed.Calendar state={state} sx={{ mt: 7, mx: 1 }} />;
  } else if (drawerView === "FILTER_VIEW") {
    title = "Filters";
    content = (
      <UI.Box sx={{ mt: 6 }}>
        <UI.SessionsFeed.Filters state={state} sx={{ m: 2 }} />
        <ShowSocialVisitsLink />
      </UI.Box>
    );
  }

  return (
    <UI.Drawer
      anchor="right"
      open={drawers.filters.open}
      onClose={drawers.filters.onClose}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <UI.DrawerHeader
        title={title}
        anchor="right"
        onClose={drawers.filters.onClose}
        open={drawers.filters.open}
      />
      {content}
    </UI.Drawer>
  );
};

export default Drawer;
