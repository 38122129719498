import * as UI from "@goodgym/components";
import Banner from "@goodgym/components/Banner";
import ReactPlayer from "react-player";
import PostcodeSearch from "../components/PostcodeSearch";
import FAQ from "../components/FAQ";
import NewsQuotes from "../components/NewsQuotes";
import AboutSocialVisit from "./components/AboutSocialVisit";
import GetInvolvedCard from "./components/GetInvolvedCard";
import MidBanner from "./components/MidBanner";
import TestimonialRunners from "../components/TestimonialsRunners";
import { CoachTestimonials, FaqData, ImpactTestimonials } from "./data";
import * as Images from "./images";

const AboutSocialVisitPage = () => {
  return (
    <UI.Box sx={{}}>
      <Banner
        title="Visit an isolated older person for a social chat"
        image={Images.CoachRunImage}
        buttonText={"Sign up"}
        linkForButton="https://www.goodgym.org/my/coach/verification"
      />
      <AboutSocialVisit />
      <MidBanner />
      <UI.Box
        sx={{
          backgroundImage: `url(${Images.CoachRun})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "600px",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "5/3",
        }}
      ></UI.Box>
      <GetInvolvedCard />

      <UI.Typography variant="h3" sx={{ textAlign: "center", mt: 4, mb: 2 }}>
        The social visit impact
      </UI.Typography>
      <UI.Typography variant="body1" sx={{ textAlign: "center", mb: 5 }}>
        Here’s what our beneficiaries think of our social visits:
      </UI.Typography>
      <UI.Container>
        <TestimonialRunners testimonials={ImpactTestimonials} />
      </UI.Container>

      <UI.Container sx={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer
          url="https://cdn.theguardian.tv/mainwebsite/2014/06/25/140624longrun-16x9.mp4"
          controls={true}
          style={{ marginTop: "30px", marginLeft: 2, marginRight: 2 }}
        />
      </UI.Container>

      <UI.Typography variant="h3" sx={{ textAlign: "center", mt: 4, mb: 2 }}>
        Meet some coaches and goodgymers
      </UI.Typography>
      <UI.Typography variant="body1" sx={{ textAlign: "center", mb: 5 }}>
        Wondering what it was like to visit one of our coaches?
      </UI.Typography>
      <UI.Container>
        <TestimonialRunners testimonials={CoachTestimonials} />
      </UI.Container>
      <UI.Box sx={{ mt: 6, background: "#E9E9E9", p: 2 }}>
        <UI.Container sx={{}}>
          <UI.Typography variant="h4" sx={{ my: 2 }}>
            What people say about our community
          </UI.Typography>
          <NewsQuotes />
        </UI.Container>
      </UI.Box>
      <FAQ info={FaqData} />
      <UI.Box
        sx={{
          background: "#E11018",
          height: "200px",
          p: 4,
          mt: 5,
          mb: { xs: 6, md: 0 },
        }}
      >
        <UI.Container sx={{ textAlign: "center" }}>
          <PostcodeSearch />
        </UI.Container>
      </UI.Box>
    </UI.Box>
  );
};

export default AboutSocialVisitPage;
